<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('CustomPeriodicTasks')"
    @cancel="onCancel('CustomPeriodicTasks')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'CustomPeriodicTaskCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      controllerName: 'CustomPeriodicTasks',
      pageHeader: 'Создание кастомной периодической задачи',
      breadcrumbs: [
        {
          text: 'Публикация приложений',
          route: { name: 'PublishingApplicationsMain' },
        },
        {
          text: 'Кастомные периодические задачи',
          route: { name: 'CustomPeriodicTasks' },
        },
        {
          text: 'Создание кастомной периодической задачи',
        },
      ],
      initialForm: {
        name: null,
        folder: null,
        isEnabled: false,
        scheduleMode: null,
        scheduledTime: null,
        arguments: [],
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      customPeriodicTaskModes: (state) => state.common.customPeriodicTaskModes,
    }),

    fields() {
      const scheduleMode = this.customPeriodicTaskModes.data.find(
        (item) => item.id === this.form.scheduleMode
      );
      const fields = [
        {
          tab: 'Основные',
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Название задачи',
            },
            {
              type: 'text',
              key: 'folder',
              label:
                'Имя папки задачи (запрещены символы [\\,:], т.к. это будет физическая папка)',
            },
            {
              type: 'checkbox',
              key: 'isEnabled',
              label: 'Активная',
            },
            {
              type: 'select',
              key: 'scheduleMode',
              label: 'Периодичность',
              list: this.customPeriodicTaskModes.data,
            },
            {
              type: 'fullDateTime',
              key: 'scheduledTime',
              label:
                'Дата и время первого запуска, и время для последующих запусков',
            },
            {
              type: 'integerNumber',
              key: 'scheduleModeArgument',
              label: scheduleMode?.argumentName,
              hidden: !scheduleMode?.required,
            },
          ],
        },
        {
          tab: 'Параметры задачи',
          table: {
            caption: 'Параметры задачи',
            headers: [
              {
                text: 'Название аргумента',
                alias: 'name',
              },
              {
                text: 'Значение аргумента',
                alias: 'value',
              },
              {
                alias: 'actions',
              },
            ],
            items: this.form.arguments,
            key: 'arguments',
            modalFields: [
              {
                type: 'text',
                key: 'name',
                label: 'Название аргумента',
              },
              {
                type: 'text',
                key: 'value',
                label: 'Значение аргумента',
              },
            ],
            showCreateButton: true,
            showEditButton: true,
            showDeleteButton: true,
          },
        },
      ];

      fields[0].form = fields[0].form.filter((field) => !field.hidden);

      return fields;
    },
  },

  watch: {
    'form.scheduleMode': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && oldValue !== undefined) {
          const selectedScheduleModeOption =
            this.customPeriodicTaskModes.data.find(
              (item) => item.id === this.form.scheduleMode
            );

          if (!selectedScheduleModeOption?.required) {
            this.form.scheduleModeArgument = null;
          }
        }
      },
    },
  },

  created() {
    this.getCommonList({ name: 'CustomPeriodicTaskModes' });
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
